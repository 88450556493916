<template>
  <div class="accountContract">
            <van-nav-bar
              title="开户合同"
              left-arrow
              fixed
              safe-area-inset-top
              placeholder 
              @click-left="onClickLeft"
            />
            <div class="box" v-html="data"></div>
            <footer class="foot" @click="autograph" v-if="!loginout">签名确认</footer>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
    data() {
        return {
            data:'',
            loginout: "",
            userinfo: "",
            officialSeal: require('@/assets/images/people.png')
        }
    },
async    mounted() {
      this.loginout = this.$route.query.loginout ? this.$route.query.loginout : ""
        let res = await api.getAgreement('BUY_SELL')
        if(this.loginout){
          api.getuser().then(res1 => {
            this.userinfo = res1
            let newHtml = ""
            let index = res.content.indexOf("乙方:<")
            let addHtml = this.userinfo.realName
            newHtml = this.insertStr(res.content, index+3, addHtml)
            addHtml = this.userinfo.identificationCard
            index =  newHtml.indexOf("身份证号:")
            newHtml = this.insertStr(newHtml, index+5, addHtml)
            addHtml = this.userinfo.phone
            index =  newHtml.indexOf("联系电话:<")
            newHtml = this.insertStr(newHtml, index+5, addHtml)
            newHtml = newHtml.replace(/<p><\/p>/g, '')
            addHtml = `<div style="text-align:left;height: 110px;display: flex;"><img src=${this.officialSeal} width="100px" style="margin-left: 2em;">                     <img src=${this.userinfo.sign} width="80px" style="-moz-transform:rotate(-90deg);
                        -webkit-transform:rotate(-90deg);filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);"></div>`
            index =  newHtml.indexOf(`签名/签章) </p>`);
            if(newHtml.indexOf("签名/签章) </span></p>") != -1) {
              index =  newHtml.indexOf(`签名/签章) </span></p>`);
              this.data = this.insertStr(newHtml, index+18, addHtml)
            } else {
              index =  newHtml.indexOf(`签名/签章) </p>`);
              this.data = this.insertStr(newHtml, index+11, addHtml)
            }
            let authPost = new Date(this.userinfo.authPostTime*1000)
            let date = authPost.getFullYear() + '年' + (authPost.getMonth()+1) + '月' + authPost.getDate() + '日'
            this.data = this.data.replace(/年月日/g, date)
            // this.data = this.data.replace(/<p><\/p>/g, '<br>')
            console.log(this.data)
          })
        } else {
          this.data = res.content
        }
    },
    methods:{
      autograph(){
        if (this.$route.query.pricing) {
            this.$router.push({
            name:'autograph',
            query:{pricing:true}
          })
        }else{
            this.$router.push({
            name:'autograph'
          })
        }
         
      },
      onClickLeft(){
        if(this.loginout){
          this.$router.push({
            name:'Agreements'
          })
          return
        }
            this.$router.push({
              name:'person'
          })
            // const headers_ = {
            //   headers: {
            //     "Content-Type": "application/x-www-form-urlencoded"
            //   }
            // };
            // const auth = {
            //   username: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
            //   password: JSON.parse(process.env.VUE_APP_MY_PARMA).password
            // };
            // const transformRequest = [
            //   function(data) {
            //     let ret = ""
            //     for (const it in data) {
            //       ret +=
            //         encodeURIComponent(it) +
            //         "=" +
            //         encodeURIComponent(data[it]) +
            //         "&"
            //     }
            //     return ret;
            //   }
            // ];
            // let userName = JSON.parse(localStorage.getItem('phone'))
            // let password = JSON.parse(localStorage.getItem('password'))

            // registerbox.login( {
            //   username: userName += "|MOBILE_PWA",
            //   password: password,
            //   scope: "write",
            //   grant_type: "password",
            //   client_secret: JSON.parse(process.env.VUE_APP_MY_PARMA).password,
            //   client_id: JSON.parse(process.env.VUE_APP_MY_PARMA).username
            // }, {auth, transformRequest,headers_ })
            //   .then((res) => {
            //     if (res.access_token) {
            //       localStorage.setItem("token", res.access_token)
            //       localStorage.setItem("refresh_token", res.refresh_token)
            //       localStorage.setItem("isSevrice",'1')
            //       const userName = String(this.RegisterForm.phone)
            //       localStorage.setItem("moible", userName)
            //       // localStorage.setItem("sela",getuser.getuser().then(res => {res.uid}));
            //       // localStorage.setItem("sela1","（北京天雅）检测中心");
            //       // localStorage.setItem("sela2" ,"北京市东城区 法华寺街136号天雅珠宝城1楼1076");
            //       this.registerSuccess = false
            //       this.$router.push({
            //         name:'certification'
            //       })
            //     }
            //   })
            //   .catch((err) => {
              //   this.$toast.fail("出错啦!请稍后再试...")
              // })
      },
      insertStr (str, index, insertStr) {
        return str.substring(0, index) + insertStr + str.substring(index)
      }
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
     .van-nav-bar{
        height: 96px;
        .van-nav-bar__content{
        line-height: 96px;
        height: 96px;
        .van-nav-bar__title{
            font-size: 36px;
            color: #212121;
        }
        .van-icon{
            font-size: 40px;
            color: #212121;
            right: 4px;
        }
        }
     }
  .box {
    p:nth-child(1) {
      display: inherit !important;
    }
    p:nth-child(3) {
      display: inherit !important;
    }
    p:nth-child(4) {
      display: inherit !important;
    }
    p:nth-child(5) {
      display: inherit !important;
    }
    p:nth-child(6) {
      display: inherit !important;
    }
    p:nth-child(7) {
      display: inherit !important;
    }
    p:nth-child(8) {
      display: inherit !important;
    }
  }

}
.box{
  padding: 30px 30px 100px;
  font-size: 32px;
  color: #212121;
  //text-align: center;
}
.flex{
  display: flex;
  padding: 6px 0;
}
.flex_between{
     display: flex;
     justify-content: space-between;
     padding: 6px 0;
}
.info{
  padding-left: 14px;
}
.marginTop{
  margin-top: 16px;
}
.title{
  text-indent:2em
}
.contractInfo{
  padding: 10px 0;
}
.qianMing{
  padding: 0 40px 0px 10px;
  margin: 30px 0 50px;
}
.foot{
  height: 84px;
  width: 100%;
  text-align: center;
  line-height: 84px;
  font-size: 32px;
  color: #fff;
  background: #FF2D2E;
  position: fixed;
  left: 0;
  bottom: 0;
}
</style>