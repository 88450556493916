var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accountContract" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "开户合同",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c("div", {
        staticClass: "box",
        domProps: { innerHTML: _vm._s(_vm.data) }
      }),
      !_vm.loginout
        ? _c("footer", { staticClass: "foot", on: { click: _vm.autograph } }, [
            _vm._v("签名确认")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }